import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config/config';
import './AdminLogin.css';

const AdminLogin: React.FC = () => {
  const navigate = useNavigate();
  
  const onFinish = async (values: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          username: values.username,
          password: values.password
        })
      });

      if (!response.ok) {
        throw new Error('管理员账号或密码错误');
      }

      const data = await response.json();
      
      // 存储管理员登录状态和token
      localStorage.setItem('isAdmin', 'true');
      localStorage.setItem('adminToken', data.token);
      
      message.success('登录成功');
      navigate('/admin/dashboard');
    } catch (error) {
      message.error('登录失败，请重试');
    }
  };

  return (
    <div className="admin-login-container">
      <div className="admin-login-box">
        <h2>管理员登录</h2>
        <Form
          name="admin_login"
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入管理员账号!' }]}
          >
            <Input 
              prefix={<UserOutlined />} 
              placeholder="管理员账号" 
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入管理员密码!' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="管理员密码"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AdminLogin; 