import { CopyOutlined, DeleteOutlined, InboxOutlined, MenuFoldOutlined, MenuUnfoldOutlined, RotateLeftOutlined, RotateRightOutlined, ScissorOutlined } from '@ant-design/icons';
import { Upload as AntUpload, Button, Drawer, message, Modal, Select, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ReactMarkdown from 'react-markdown';
import { useNavigate, Link } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { API_BASE_URL } from '../config/config';
import './Upload.css';

const { Dragger } = AntUpload;

// 添加语言接口定义
interface Language {
  code: string;
  name: string;
}

// 添加文件记录接口
interface FileRecord {
  fileName: string;
  imageUrl: string;
  text: string;
  languages: Language[];
}

// 修改扫描记录接口
interface ScanRecord {
  id: number;
  timestamp: string;
  text: string;
  imageUrl: string;
  languages: Language[];
  files?: FileRecord[];  // 添加可选的 files 属性
}

interface FileItem {
  file: File;
  imageUrl: string;
  text?: string;
  languages?: Language[];
  isScanning?: boolean;
  rotation?: number;  // 添加旋转角度属性
}

const Upload: React.FC = () => {
  const navigate = useNavigate();
  const [recognizedText, setRecognizedText] = useState<string>('');
  const [messageKey] = useState('uploadMessage');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [isScanning, setIsScanning] = useState(false);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [detectedLanguages, setDetectedLanguages] = useState<Language[]>([]);  // 修改类型
  const [scanRecords, setScanRecords] = useState<ScanRecord[]>([]);
  const imageRef = useRef<HTMLImageElement>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerHovered, setDrawerHovered] = useState(false);
  const drawerTimeoutRef = useRef<NodeJS.Timeout>();
  const [fileList, setFileList] = useState<FileItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [uploadKey, setUploadKey] = useState<number>(0);  // 添加 key 来强制重新渲染上传组件
  const [imagesDrawerVisible, setImagesDrawerVisible] = useState(false);
  const [imagesDrawerHovered, setImagesDrawerHovered] = useState(false);
  const imagesDrawerTimeoutRef = useRef<NodeJS.Timeout>();
  const [cropModalVisible, setCropModalVisible] = useState(false);
  const [currentCropImage, setCurrentCropImage] = useState<string>('');
  const [currentCropIndex, setCurrentCropIndex] = useState<number>(-1);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 0,
    height: 0,
    x: 0,
    y: 0
  });
  const [rotation, setRotation] = useState(0);
  const [translatedText, setTranslatedText] = useState<string>('');
  const [answer, setAnswer] = useState<string>('');
  const [targetLanguage, setTargetLanguage] = useState<string>('zh');
  const [supportedLanguages, setSupportedLanguages] = useState<Language[]>([]);

  // 添加新的 refs
  const translatedTextRef = useRef<HTMLDivElement>(null);
  const answerTextRef = useRef<HTMLDivElement>(null);

  // 在组件顶部添加状态
  const [isTranslating, setIsTranslating] = useState(false);

  // 在组件内添加检查函数
  const checkAuth = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      message.error('请先登录');
      navigate('/login');
      return false;
    }
    return true;
  };

  const handleScan = async () => {
    if (!checkAuth()) return;
    if (!currentFile) return;

    setIsScanning(true);
    try {
      // 检查文件大小
      if (currentFile.size > 10 * 1024 * 1024) { // 10MB 限
        throw new Error('文件大小不能超过10MB');
      }

      // 检查文件类型
      if (!currentFile.type.startsWith('image/')) {
        throw new Error('只支持图片文件');
      }

      const formData = new FormData();
      formData.append('file', currentFile);

      const response = await fetch(`${API_BASE_URL}/api/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData, // 使用 FormData 而不是 JSON
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.detail || `上传失败: ${response.status}`);
      }

      const data = await response.json();
      setRecognizedText(data.text);
      setDetectedLanguages(data.detected_languages || []);
      
      message.success({ content: '扫描完成', key: messageKey });
    } catch (error) {
      console.error('Scan error:', error);
      message.error({ 
        content: error instanceof Error ? error.message : '扫描失败，请重试', 
        key: messageKey 
      });
    } finally {
      setIsScanning(false);
    }
  };

  const handleClear = () => {
    setFileList([]);
    setCurrentIndex(-1);
    setRecognizedText('');
    setDetectedLanguages([]);
    setUploadKey(prev => prev + 1);  // 更新 key 以重置上传组件
  };

  const handleDelete = (index: number) => {
    setFileList(prev => {
      const newList = prev.filter((_, i) => i !== index);
      if (newList.length === 0) {
        setUploadKey(prev => prev + 1);  // 如果删除完所有文件，重置上传组件
      }
      return newList;
    });
  };

  const props = {
    name: 'file',
    multiple: true,
    accept: 'image/*',
    key: uploadKey,  // 添加 key 属性
    beforeUpload: (file: File, files: File[]) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error({ 
          content: '只能上传图片文件！',
          key: messageKey 
        });
        return false;
      }
      return false;
    },
    onChange: async (info: any) => {
      const { fileList: newFileList } = info;
      
      if (newFileList.length > 10) {
        message.warning('最多只能上传10个文件');
        return;
      }

      const items: FileItem[] = newFileList.map((file: any) => ({
        file: file.originFileObj,
        imageUrl: URL.createObjectURL(file.originFileObj),
      }));

      setFileList(items);
      setRecognizedText('');
      setDetectedLanguages([]);
      setCurrentIndex(-1);
    },
    showUploadList: false,
    fileList: [],  // 确保组件内部的文件列表为空
  };

  // 修改历史记录点击处理函数
  const handleRecordClick = (record: ScanRecord) => {
    // 清除当前的文件列表和状
    setFileList([]);
    setCurrentIndex(-1);
    
    // 设置历史记录的内容
    setImageUrl(record.imageUrl);
    setRecognizedText(record.text);
    setDetectedLanguages(record.languages);
    
    // 创建一个新的文件项
    const response = fetch(record.imageUrl)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'restored-image.jpg', { type: 'image/jpeg' });
        const fileItem: FileItem = {
          file: file,
          imageUrl: record.imageUrl,
          text: record.text,
          languages: record.languages
        };
        setFileList([fileItem]);
      })
      .catch(error => {
        console.error('Error restoring image:', error);
        message.error('恢复历史记录失败');
      });

    message.success('已恢复历史记录');
  };

  // 处理抽屉悬停
  const handleDrawerHover = (visible: boolean) => {
    if (drawerTimeoutRef.current) {
      clearTimeout(drawerTimeoutRef.current);
    }
    
    if (visible) {
      setDrawerHovered(true);
      setDrawerVisible(true);
    } else {
      drawerTimeoutRef.current = setTimeout(() => {
        if (!drawerHovered) {
          setDrawerVisible(false);
        }
      }, 300);
    }
  };

  // 修改批量扫描函数
  const handleBatchScan = async () => {
    if (!checkAuth()) return;
    if (fileList.length === 0) return;
    
    setCurrentIndex(0);
    
    try {
      setFileList(prev => prev.map(f => ({ ...f, isScanning: true })));

      const results = await Promise.all(fileList.map(async (item) => {
        const formData = new FormData();
        formData.append('file', item.file);

        const response = await fetch(`${API_BASE_URL}/api/upload`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`扫描失败: ${item.file.name}`);
        }

        const data = await response.json();
        return {
          fileName: item.file.name,
          imageUrl: item.imageUrl,
          text: data.text,
          languages: data.detected_languages || []
        };
      }));

      setFileList(prev => prev.map((item, index) => ({
        ...item,
        text: results[index].text,
        languages: results[index].languages,
        isScanning: false
      })));

      message.success('���量扫描完成');

    } catch (error) {
      console.error('批量扫描错误:', error);
      message.error(error instanceof Error ? error.message : '批量扫描失败');
    } finally {
      setCurrentIndex(-1);
    }
  };

  // 添加复制功能
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('复制成功');
    }).catch(() => {
      message.error('复制失败');
    });
  };

  // 从 props 中分出 key
  const { key, ...otherProps } = props;

  // 添加自动滚动功能
  const resultRef = useRef<HTMLDivElement>(null);

  // 在扫描完成后滚动到结果区域
  useEffect(() => {
    if (fileList.some(item => item.text && !item.isScanning)) {
      resultRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [fileList]);

  // 处理左侧图片抽屉悬停
  const handleImagesDrawerHover = (visible: boolean) => {
    if (imagesDrawerTimeoutRef.current) {
      clearTimeout(imagesDrawerTimeoutRef.current);
    }
    
    if (visible) {
      setImagesDrawerHovered(true);
      setImagesDrawerVisible(true);
    } else {
      imagesDrawerTimeoutRef.current = setTimeout(() => {
        if (!imagesDrawerHovered) {
          setImagesDrawerVisible(false);
        }
      }, 300);
    }
  };

  // 获取容器类名
  const getContainerClassName = () => {
    if (drawerVisible && imagesDrawerVisible) {
      return 'drawer-open-both';
    } else if (drawerVisible) {
      return 'drawer-open-right';
    } else if (imagesDrawerVisible) {
      return 'drawer-open-left';
    }
    return '';
  };

  // 修改裁剪点击处理函数
  const handleCropClick = (imageUrl: string, index: number) => {
    setCurrentCropImage(imageUrl);
    setCurrentCropIndex(index);
    // 同步当前图片的旋转角度
    setRotation(fileList[index].rotation || 0);
    setCrop({
      unit: '%',
      width: 0,
      height: 0,
      x: 0,
      y: 0
    });
    setCropModalVisible(true);
  };

  const handleCropComplete = async () => {
    if (!currentCropImage || currentCropIndex === -1) return;

    const image = new Image();
    image.src = currentCropImage;
    
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    // 获取图片元素
    const imgElement = document.querySelector('.ReactCrop img') as HTMLImageElement;
    if (!imgElement) return;

    // 获图片的实际显示尺寸
    const displayRect = imgElement.getBoundingClientRect();
    const displayWidth = displayRect.width;
    const displayHeight = displayRect.height;

    // 计算缩放比例
    const scaleX = image.naturalWidth / displayWidth;
    const scaleY = image.naturalHeight / displayHeight;

    // 创建临时canvas用于旋转
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    if (!tempCtx) return;

    // 设置临时canvas的大小为原图大小
    tempCanvas.width = image.naturalWidth;
    tempCanvas.height = image.naturalHeight;

    // 如果有旋转，先处理旋转
    if (rotation !== 0) {
      // 根据旋转角度调整canvas大小
      if (Math.abs(rotation) === 90 || Math.abs(rotation) === 270) {
        tempCanvas.width = image.naturalHeight;
        tempCanvas.height = image.naturalWidth;
      }

      // 移动到中心点
      tempCtx.translate(tempCanvas.width / 2, tempCanvas.height / 2);
      tempCtx.rotate((rotation * Math.PI) / 180);
      tempCtx.drawImage(
        image,
        -image.naturalWidth / 2,
        -image.naturalHeight / 2,
        image.naturalWidth,
        image.naturalHeight
      );
    } else {
      // 无旋转时直接绘制
      tempCtx.drawImage(image, 0, 0);
    }

    // 创建最终的裁剪canvas
    const cropCanvas = document.createElement('canvas');
    const cropCtx = cropCanvas.getContext('2d');
    if (!cropCtx) return;

    // 计算裁剪区域（虑旋转后的坐标）
    const cropX = (crop.x! / 100) * displayWidth * scaleX;
    const cropY = (crop.y! / 100) * displayHeight * scaleY;
    const cropWidth = (crop.width! / 100) * displayWidth * scaleX;
    const cropHeight = (crop.height! / 100) * displayHeight * scaleY;

    // 设置裁剪canvas的大小
    cropCanvas.width = cropWidth;
    cropCanvas.height = cropHeight;

    // 从旋转后的图像中裁剪
    cropCtx.drawImage(
      tempCanvas,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    // 转换为Blob
    cropCanvas.toBlob(
      (blob) => {
        if (!blob) return;

        // 更新文件列表
        setFileList(prev => prev.map((item, index) => {
          if (index === currentCropIndex) {
            const newFile = new File([blob], item.file.name, { 
              type: 'image/jpeg'
            });
            return {
              ...item,
              file: newFile,
              imageUrl: URL.createObjectURL(blob),
              rotation: 0  // 重置旋转角度
            };
          }
          return item;
        }));

        setCropModalVisible(false);
        setRotation(0);
        setCrop({ unit: '%', width: 0, height: 0, x: 0, y: 0 });
      },
      'image/jpeg',
      1.0
    );
  };

  // 添加全局拖拽处理
  useEffect(() => {
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      
      const files = Array.from(e.dataTransfer?.files || []);
      if (files.length > 10) {
        message.warning('最多只能上传10个文件');
        return;
      }

      const imageFiles = files.filter(file => file.type.startsWith('image/'));
      if (imageFiles.length !== files.length) {
        message.warning('只能上传图片文件');
        return;
      }

      const items: FileItem[] = imageFiles.map(file => ({
        file,
        imageUrl: URL.createObjectURL(file),
      }));
      setFileList(prev => [...prev, ...items]);
    };

    // 添加全局拖拽监听
    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('drop', handleDrop);

    return () => {
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('drop', handleDrop);
    };
  }, []);

  // 修改翻译函数
  const handleTranslate = async (text: string) => {
    if (!checkAuth()) return;
    if (!text) {
      message.warning('没有可翻译的文本');
      return;
    }

    try {
      setIsTranslating(true);
      message.loading({ content: '正在翻译...', key: 'translating' });
      
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('未登录或登录已过期');
      }

      // 使用与 OCR 相同的 API 路径格式
      const response = await fetch(`${API_BASE_URL}/api/translate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ 
          text: text,
          target_language: targetLanguage 
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.detail || '翻译服务暂时不可用');
      }

      if (!data.translated_text) {
        throw new Error('翻译结果为空');
      }
      
      setTranslatedText(data.translated_text);
      message.success({ content: '翻译完成', key: 'translating' });
      
      setTimeout(() => {
        translatedTextRef.current?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'center'
        });
      }, 100);
    } catch (error) {
      console.error('翻译失败:', error);
      message.error({ 
        content: error instanceof Error ? error.message : '翻译服务暂时不可用，请稍后重试', 
        key: 'translating' 
      });
    } finally {
      setIsTranslating(false);
    }
  };

  // 修改答题函数
  const handleAnswer = async (text: string) => {
    if (!checkAuth()) return;
    if (!text) {
      message.warning('没有可回答的问题');
      return;
    }

    try {
      message.loading({ content: '正在思考...', key: 'answering' });
      
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('未登录或登录已过期');
      }

      // 使用与 OCR 相同的 API 路径格式
      const response = await fetch(`${API_BASE_URL}/api/answer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ question: text })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.detail || 'AI服务暂时不可用');
      }

      if (!data.answer) {
        throw new Error('未能获取到答案');
      }

      setAnswer(data.answer);
      message.success({ content: '回答完成', key: 'answering' });
      
      setTimeout(() => {
        answerTextRef.current?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'center'
        });
      }, 100);
    } catch (error) {
      console.error('答题失败:', error);
      message.error({ 
        content: error instanceof Error ? error.message : 'AI服务暂时不可用，请稍后重试',
        key: 'answering'
      });
    }
  };

  // 在组件加载时获取支持的语言列表
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/languages`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('获取语言列表失败');
        }
        const data = await response.json();
        // 将对象转换为数组格式
        const languageList = Object.entries(data).map(([code, name]) => ({
          code,
          name: String(name),
          label: `${name} (${code})`,  // 添加 label 属性
          value: code  // 添加 value 属性
        }));
        setSupportedLanguages(languageList);
        // 默认设置为中文
        setTargetLanguage('zh');
      } catch (error) {
        console.error('��取语言列表失败:', error);
        message.error('获取支持的语言列表失败');
      }
    };

    fetchLanguages();
  }, []);

  return (
    <div className={`page-container ${getContainerClassName()}`}>
      {/* 左侧图片预览触发器 */}
      <div 
        className={`images-trigger ${imagesDrawerVisible ? 'active' : ''}`}
        onMouseEnter={() => handleImagesDrawerHover(true)}
        onMouseLeave={() => handleImagesDrawerHover(false)}
      >
        {imagesDrawerVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </div>

      {/* 左侧图片预览抽屉 */}
      <Drawer
        title="图片预览"
        placement="left"
        closable={false}
        onClose={() => setImagesDrawerVisible(false)}
        open={imagesDrawerVisible}
        mask={false}
        className="images-drawer"
        width={320}
        onMouseEnter={() => setImagesDrawerHovered(true)}
        onMouseLeave={() => {
          setImagesDrawerHovered(false);
          setImagesDrawerVisible(false);
        }}
      >
        <div className="images-preview">
          {fileList.map((item, index) => (
            <div key={index} className="image-item">
              <div className="image-preview-large">
                <img 
                  src={item.imageUrl} 
                  alt={`图片 ${index + 1}`} 
                  style={{ 
                    transform: `rotate(${item.rotation || 0}deg)`,
                    transition: 'transform 0.3s'
                  }}
                />
                <div className="image-actions">
                  <div 
                    className="image-action-button"
                    onClick={() => {
                      setFileList(prev => prev.map((f, i) => 
                        i === index ? { ...f, rotation: ((f.rotation || 0) - 90) % 360 } : f
                      ));
                    }}
                  >
                    <RotateLeftOutlined />
                  </div>
                  <div 
                    className="image-action-button"
                    onClick={() => handleCropClick(item.imageUrl, index)}
                  >
                    <ScissorOutlined />
                  </div>
                  <div 
                    className="image-action-button"
                    onClick={() => {
                      setFileList(prev => prev.map((f, i) => 
                        i === index ? { ...f, rotation: ((f.rotation || 0) + 90) % 360 } : f
                      ));
                    }}
                  >
                    <RotateRightOutlined />
                  </div>
                </div>
                <div className="image-delete">
                  <div 
                    className="image-delete-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(index);
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
              </div>
              <div className="image-info">
                <div>{item.file.name}</div>
                <div>{new Date().toLocaleString()}</div>
              </div>
            </div>
          ))}
        </div>
      </Drawer>

      {/* 主要内容区域 */}
      <div className="main-content">
        <div className="vision-section">
          <h2>Super OCR 愿景</h2>
          <div className="vision-content">
            <p>
              在这个知识与信息爆炸的AI时代，每个学生都面临着巨大的学习压力。我们看到太多年轻人被动地应付考试，
              被迫记忆大量可能永远用不到的知识，却错过了发现自己真正热爱的机会。
            </p>
            <p>
              我们相信，真正的学习应该源于好奇和热情，而不是被动的考试压力。在这个AI技术日新月异的时代，
              学生最需要的不是机械的记忆，而是学会如何高效地获取信息、分析问题，并做出明智的决策。
            </p>
            <p>
              这就是为什么我们创建了 Super OCR。我们希望通过这个平台，帮助学生摆脱繁琐的考试压力，
              让他们能够更专注于真正感兴趣的领域。无论是快速获取文字信息，还是跨越语言障碍，
              亦或是寻求智能解答，我们都希望能为每个求知者提供最便捷的工具。
            </p>
            <p>
              让学习回归本质，让知识服务于真正的成长。这不仅仅是一个OCR工具，
              更是我们对未来教育的一个美好期待——当每个人都能自由地追求知识，
              教育的真谛才能真正实现。
            </p>
          </div>
        </div>

        <div className="upload-container">
          {/* 上部分：上传区域和预览 */}
          <div className="upload-top">
            {/* 左侧上传区域 */}
            <div className="upload-left">
              <div className="custom-upload-area" onClick={() => document.querySelector<HTMLInputElement>('input[type="file"]')?.click()}>
                <div className="upload-content">
                  <div className="upload-icon">
                    <InboxOutlined />
                  </div>
                  <div className="upload-text">点击或拖拽文件</div>
                  <div className="upload-hint">支持批量上传图片</div>
                </div>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    const files = Array.from(e.target.files || []);
                    if (files.length > 10) {
                      message.warning('最多只能上传10个文件');
                      return;
                    }
                    const items: FileItem[] = files.map(file => ({
                      file,
                      imageUrl: URL.createObjectURL(file),
                    }));
                    setFileList(items);
                  }}
                />
              </div>

              {fileList.length > 0 && (
                <div className="batch-controls">
                  <Button 
                    type="primary"
                    onClick={handleBatchScan}
                    disabled={currentIndex !== -1}
                  >
                    开始批量扫描
                  </Button>
                  <Button onClick={handleClear}>
                    清除所有
                  </Button>
                </div>
              )}
            </div>

            {/* 右侧预览列表 */}
            <div className="file-list">
              {fileList.map((item, index) => (
                <div key={index} className="file-item">
                  <div className="file-preview-wrapper">
                    <div 
                      className="file-delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                    >
                      <DeleteOutlined />
                    </div>
                    <div className="file-preview">
                      <img src={item.imageUrl} alt={`预览 ${index + 1}`} />
                    </div>
                    <div className="file-name" title={item.file.name}>
                      {item.file.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* 底部结果区域 */}
          {fileList.length > 0 && (
            <div className="result-container" ref={resultRef}>
              <div className="result-content">
                <div className="result-header">
                  <h3>识别结果</h3>
                  <div 
                    className="copy-button"
                    onClick={() => handleCopy(fileList.map(item => item.text).join('\n\n'))}
                  >
                    <CopyOutlined />
                  </div>
                </div>
                
                {fileList.map((item, index) => (
                  <div key={index} className="batch-result-item">
                    {item.isScanning ? (
                      <p>正在扫描...</p>
                    ) : (
                      <div>
                        <h4>文件: {item.file.name}</h4>
                        <pre style={{ 
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word',
                          maxHeight: '400px',
                          overflowY: 'auto',
                          backgroundColor: '#f5f5f5',
                          padding: '10px',
                          borderRadius: '4px'
                        }}>
                          {item.text || '等待扫描...'}
                        </pre>
                        
                        {item.text && (
                          <div className="action-buttons">
                            <Select
                              value={targetLanguage}
                              onChange={setTargetLanguage}
                              style={{ width: 200, marginRight: 16 }}
                              options={supportedLanguages}  // 直接使用处理好的 options
                              placeholder="选择目标语言"
                            />
                            <Button 
                              type="primary" 
                              onClick={() => handleTranslate(item.text!)}
                              className="action-button translate-btn"
                              loading={isTranslating}
                            >
                              翻译
                            </Button>
                            <Button 
                              type="primary" 
                              onClick={() => handleAnswer(item.text!)}
                              className="action-button answer-btn"
                            >
                              答题
                            </Button>
                          </div>
                        )}

                        {/* 添加 ref 到结果区域 */}
                        {translatedText && (
                          <div className="translated-text" ref={translatedTextRef}>
                            <h3>翻译结果 ({supportedLanguages.find(lang => lang.code === targetLanguage)?.name})：</h3>
                            <div className="markdown-content">
                              <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                              >
                                {translatedText}
                              </ReactMarkdown>
                            </div>
                          </div>
                        )}

                        {answer && (
                          <div className="answer-text" ref={answerTextRef}>
                            <h3>答案：</h3>
                            <p>{answer}</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div 
        className={`history-trigger ${drawerVisible ? 'active' : ''}`}
        onMouseEnter={() => handleDrawerHover(true)}
        onMouseLeave={() => handleDrawerHover(false)}
      >
        {drawerVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </div>

      <Drawer
        title="描记录"
        placement="right"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        mask={false}
        className="history-drawer"
        width={320}
        onMouseEnter={() => setDrawerHovered(true)}
        onMouseLeave={() => {
          setDrawerHovered(false);
          setDrawerVisible(false);
        }}
      >
        <div className="scan-history">
          {scanRecords.map((record) => (
            <div 
              key={record.id} 
              className="scan-record"
              onClick={() => handleRecordClick(record)}
            >
              <div className="scan-record-image">
                <img src={record.imageUrl} alt="扫描记录" />
              </div>
              <div className="scan-record-content">
                <div className="scan-record-time">{record.timestamp}</div>
                <div className="scan-record-languages">
                  {record.languages.map(lang => (
                    <Tag key={lang.code} color="blue">
                      {lang.name}
                    </Tag>
                  ))}
                </div>
                <div className="scan-record-text">{record.text}</div>
              </div>
            </div>
          ))}
        </div>
      </Drawer>

      {/* 添加剪裁模态框 */}
      <Modal
        title="图片剪裁"
        open={cropModalVisible}
        onOk={handleCropComplete}
        onCancel={() => {
          setCropModalVisible(false);
          setCrop({ unit: '%', width: 0, height: 0, x: 0, y: 0 });
          setRotation(0);
        }}
        width={1000}
        className="crop-modal"
        okButtonProps={{ disabled: !crop.width || !crop.height }}
      >
        <div className="crop-container">
          {currentCropImage && (
            <div className="crop-image-container">
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCrop(c)}
                style={{ width: '100%', height: '100%' }}
                aspect={undefined}
              >
                <div className="crop-image-wrapper">
                  <img 
                    src={currentCropImage} 
                    alt="剪裁图片"
                    style={{ 
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                      transform: `rotate(${rotation}deg)`,
                      transition: 'transform 0.3s'
                    }}
                  />
                </div>
              </ReactCrop>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Upload; 