import { Layout as AntLayout, Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Layout.css';

const { Header, Content } = AntLayout;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('token');

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    navigate('/login');
  };

  return (
    <AntLayout className="layout">
      <Header style={{ background: '#fff', padding: '0 50px' }}>
        <div className="header-content">
          <div className="logo-section">
            <img 
              src="/logo192.png" 
              alt="OCR Logo" 
              style={{ height: '32px', marginRight: '16px' }} 
            />
            <h1>Super OCR</h1>
          </div>
          <div className="auth-buttons">
            {isLoggedIn ? (
              <>
                <Button 
                  type="link" 
                  onClick={() => navigate('/admin/dashboard')}
                >
                  管理后台
                </Button>
                <Button 
                  type="primary" 
                  onClick={handleLogout}
                >
                  退出登录
                </Button>
              </>
            ) : (
              <>
                <Button 
                  type="link" 
                  onClick={() => navigate('/login')}
                >
                  登录
                </Button>
                <Button 
                  type="primary" 
                  onClick={() => navigate('/register')}
                >
                  注册
                </Button>
              </>
            )}
          </div>
        </div>
      </Header>
      <Content style={{ padding: '50px' }}>
        {children}
      </Content>
    </AntLayout>
  );
};

export default Layout; 