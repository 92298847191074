import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const token = localStorage.getItem('token');
  const location = useLocation();
  
  // 如果是主页面，允许访问
  if (location.pathname === '/') {
    return <>{children}</>;
  }
  
  // 对于其他受保护的路由，需要验证登录
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute; 