// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: transparent;
}

.login-box {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.login-box h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #1890ff;
}

.login-links {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
}

.login-links a {
  color: #1890ff;
  cursor: pointer;
}

.login-links a:hover {
  color: #40a9ff;
}

.login-box .ant-input-affix-wrapper {
  background: rgba(255, 255, 255, 0.9);
}

.login-box .ant-btn {
  background: rgba(24, 144, 255, 0.9);
  border: none;
}

.login-box .ant-btn:hover {
  background: rgba(64, 169, 255, 0.9);
} `, "",{"version":3,"sources":["webpack://./src/pages/Login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,oCAAoC;EACpC,2BAA2B;EAC3B,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,mCAAmC;EACnC,YAAY;AACd;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".login-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n  background: transparent;\n}\n\n.login-box {\n  width: 100%;\n  max-width: 400px;\n  padding: 40px;\n  background: rgba(255, 255, 255, 0.8);\n  backdrop-filter: blur(10px);\n  border-radius: 8px;\n  box-shadow: 0 2px 8px rgba(0,0,0,0.1);\n}\n\n.login-box h2 {\n  text-align: center;\n  margin-bottom: 30px;\n  color: #1890ff;\n}\n\n.login-links {\n  display: flex;\n  justify-content: space-between;\n  margin-top: -10px;\n}\n\n.login-links a {\n  color: #1890ff;\n  cursor: pointer;\n}\n\n.login-links a:hover {\n  color: #40a9ff;\n}\n\n.login-box .ant-input-affix-wrapper {\n  background: rgba(255, 255, 255, 0.9);\n}\n\n.login-box .ant-btn {\n  background: rgba(24, 144, 255, 0.9);\n  border: none;\n}\n\n.login-box .ant-btn:hover {\n  background: rgba(64, 169, 255, 0.9);\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
