// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  min-height: 100vh;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo-section h1 {
  margin: 0;
  color: #1890ff;
}

.auth-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ant-layout-content {
  background: #fff;
} `, "",{"version":3,"sources":["webpack://./src/components/Layout.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".layout {\n  min-height: 100vh;\n}\n\n.header-content {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 100%;\n}\n\n.logo-section {\n  display: flex;\n  align-items: center;\n}\n\n.logo-section h1 {\n  margin: 0;\n  color: #1890ff;\n}\n\n.auth-buttons {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n\n.ant-layout-content {\n  background: #fff;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
