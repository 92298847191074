import { ReloadOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Form, Input, List, Modal, Switch, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/config';
import './Admin.css';

interface User {
  id: number;
  username: string;
  email: string;
  is_active: boolean;
  is_admin: boolean;
  created_at: string;
}

const Admin: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [backupFiles, setBackupFiles] = useState<any[]>([]);
  const [restoreModalVisible, setRestoreModalVisible] = useState(false);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      console.log('开始获取用户列表...');
      
      const response = await fetch(`${API_BASE_URL}/api/users`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      console.log('API响应状态:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('API错误:', errorData);
        throw new Error(errorData.detail || '获取用户列表失败');
      }

      const data = await response.json();
      console.log('获取到的用户数据:', data);
      setUsers(data);
    } catch (error) {
      console.error('获取用户列表失败:', error);
      message.error(error instanceof Error ? error.message : '获取用户列表失败');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    
    const interval = setInterval(fetchUsers, 30000);
    
    return () => clearInterval(interval);
  }, []);

  const handleRefresh = () => {
    fetchUsers();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '状态',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (active: boolean) => (
        <span style={{ color: active ? 'green' : 'red' }}>
          {active ? '活跃' : '禁用'}
        </span>
      ),
    },
    {
      title: '管理员',
      dataIndex: 'is_admin',
      key: 'is_admin',
      render: (admin: boolean) => (admin ? '是' : '否'),
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => new Date(date).toLocaleString(),
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: User) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>
            编辑
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>
            删除
          </Button>
        </span>
      ),
    },
  ];

  const handleEdit = (user: User) => {
    setEditingUser(user);
    form.setFieldsValue(user);
    setModalVisible(true);
  };

  const handleDelete = async (userId: number) => {
    try {
      await fetch(`${API_BASE_URL}/api/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('删除成功');
      fetchUsers();
    } catch (error) {
      message.error('删除失败');
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      if (editingUser) {
        await fetch(`${API_BASE_URL}/api/users/${editingUser.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(values),
        });
        message.success('更新成功');
      } else {
        await fetch(`${API_BASE_URL}/api/users`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(values),
        });
        message.success('创建成功');
      }
      setModalVisible(false);
      form.resetFields();
      setEditingUser(null);
      fetchUsers();
    } catch (error) {
      message.error(editingUser ? '更新失败' : '创建失败');
    }
  };

  const fetchBackups = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/backups`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error('获取备份列表失败');
      }
      const data = await response.json();
      setBackupFiles(data);
    } catch (error) {
      message.error('获取备份列表失败');
    }
  };

  useEffect(() => {
    fetchBackups();
  }, []);

  const handleRestore = async (filename: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/restore/${filename}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('恢复失败');
      }
      
      message.success('数据恢复成功');
      fetchUsers();
      setRestoreModalVisible(false);
    } catch (error) {
      message.error('恢复失败');
    }
  };

  return (
    <div className="admin-container">
      <div className="admin-header">
        <h2>用户管理</h2>
        <div>
          <Button 
            onClick={handleRefresh} 
            style={{ marginRight: 16 }}
            icon={<ReloadOutlined />}
          >
            刷新
          </Button>
          <Button 
            type="primary" 
            onClick={() => {
              setEditingUser(null);
              form.resetFields();
              setModalVisible(true);
            }}
          >
            添加用户
          </Button>
          <Button 
            onClick={() => setRestoreModalVisible(true)}
            icon={<UndoOutlined />}
          >
            恢复数据
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        loading={loading}
      />

      <Modal
        title={editingUser ? '编辑用户' : '添加用户'}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            name="username"
            label="用户名"
            rules={[{ required: true, message: '请输入用户名' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="邮箱"
            rules={[
              { required: true, message: '请输入邮箱' },
              { type: 'email', message: '请输入有效的邮箱地址' }
            ]}
          >
            <Input />
          </Form.Item>

          {!editingUser && (
            <Form.Item
              name="password"
              label="密码"
              rules={[{ required: true, message: '请输入密码' }]}
            >
              <Input.Password />
            </Form.Item>
          )}

          <Form.Item
            name="is_active"
            label="状态"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            name="is_admin"
            label="管理员"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {editingUser ? '更新' : '创建'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="恢复数据"
        open={restoreModalVisible}
        onCancel={() => setRestoreModalVisible(false)}
        footer={null}
      >
        <List
          dataSource={backupFiles}
          renderItem={(file) => (
            <List.Item
              actions={[
                <Button 
                  type="primary" 
                  onClick={() => handleRestore(file.filename)}
                >
                  恢复到此备份
                </Button>
              ]}
            >
              <List.Item.Meta
                title={file.filename}
                description={`创建时间: ${new Date(file.created_at).toLocaleString()}`}
              />
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

export default Admin; 