const isDevelopment = process.env.NODE_ENV === 'development';

export const API_BASE_URL = isDevelopment 
  ? 'http://localhost:8002'  // 开发环境
  : window.location.origin;   // 生产环境，使用当前域名

// 添加日志输出
console.log('Current environment:', process.env.NODE_ENV);
console.log('API Base URL:', API_BASE_URL);

export const isDevMode = isDevelopment; 