import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config/config';
import './Login.css';

const Login: React.FC = () => {
  const navigate = useNavigate();
  
  const onFinish = async (values: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          username: values.username,
          password: values.password
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || '登录失败');
      }

      const data = await response.json();
      localStorage.setItem('token', data.access_token);
      message.success('登录成功');
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      message.error(error instanceof Error ? error.message : '登录失败，请检查用户名和密码');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>登录</h2>
        <Form
          name="login"
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入用户名!' }]}
          >
            <Input 
              prefix={<UserOutlined />} 
              placeholder="用户名" 
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入密码!' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="密码"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              登录
            </Button>
          </Form.Item>

          <div className="login-links">
            <a onClick={() => navigate('/register')}>注册账号</a>
            <a onClick={() => navigate('/forgot-password')}>忘记密码？</a>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;